import { useState } from 'react';

import { cn } from '../utils/cn';
import { LoadingSpinner } from './LoadingSpinner';

type EmbeddedPageProps = {
  src: string;
  title: string;
  className?: string;
};

export function EmbeddedPage({ src, title, className }: EmbeddedPageProps) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className={cn('relative w-full h-full', className)}>
      {isLoading && <LoadingSpinner />}
      <iframe
        src={src}
        className={cn('w-full h-full border-none', className)}
        title={title}
        onLoad={() => setIsLoading(false)}
      />
    </div>
  );
}
